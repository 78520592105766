import React from 'react';
import Pry from '../components/Pry';


export default class PryView extends React.Component {

  render() {
    return (
        <Pry query={this.props.match.params.query || ''} />
    );
  }

}
