import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import logo from './logo.svg';
import './App.css';
import PryView from './views/PryView';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'

import 'bootstrap/dist/css/bootstrap.css';

library.add(fas);



export default class App extends React.Component {

  render() {
    return (
      <Router>
        <div className='page'>
          <div className='page-main'>
            <Container fluid>

              <Route
                exact
                path='/'
                render={props => <PryView {...props} />} />

              <Route
                exact
                path='/:query'
                render={props => <PryView {...props} />} />

            </Container>
          </div>
        </div>
      </Router>
    );
  }

}
