import React from 'react';
import { Row, Col, Badge, Card, CardHeader, CardBody } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'flag-icon-css/css/flag-icon.css';


export default class Pry extends React.Component {

  state = {
    pry: {},
    query: null
  }

  getHost = async(host, query) => {
    let { data } = await axios.request(`https://${host.host}.pry.sh/${query}?trace`);

    data.location = host.location;
    data.datacenter = host.datacenter;

    let pry = Object.assign(this.state.pry, { [host.host]: data });

    this.setState({ pry })
  }

  getQuery = async() => {
    const { data: query } = await axios.request(`https://pry.sh/${this.props.query}`);
    this.setState({ query });
  }

  componentDidMount() {
    this.getQuery();

    const hosts = [
      { host: 'ams', location: 'Amsterdam, Netherlands', datacenter: 'Scaleway' },
      // { host: 'ash', location: 'Ashburn, VA', datacenter: 'Amazon AWS / us-east-1' },
      { host: 'atl', location: 'Atlanta, GA', datacenter: 'RamNode' },
      // { host: 'chs', location: 'Charleston, SC', datacenter: 'WOW Residential' },
      { host: 'mia', location: 'Miami, Fl', datacenter: 'Vultr / Choopa' },
      { host: 'nyc', location: 'NYC, NY', datacenter: 'Digital Ocean' }
      // { host: 'sea', location: 'Seattle, WA', datacenter: 'Ramnode' }
    ];

    let pry = {};

    hosts.forEach(host => {
      pry[host.host] = { location: host.location, datacenter: host.datacenter };
      this.getHost(host, this.props.query)
    });

    this.setState({ pry });
  }

  render() {
    const { query } = this.state;

    const bll = (tr) => {
      return tr.map((t,i) => (
        <Card className={`mb-2 ${!t.isPublic && 'd-none'}`}>
          <CardHeader className='p-2' style={{position: 'relative'}}>
            {(t.country && t.country.isoCode) &&
              <span className={`mr-2 flag-icon flag-icon-${t.country.isoCode.toLowerCase()} flag-icon`} />
            }

            <strong>{t.address}</strong>

            {(t.reverseDns && t.reverseDns.length) &&
             <div className='text-muted' style={{ fontSize: '85%' }}>{t.reverseDns[0].replace(/\.$/,'').toLowerCase()}</div>
            }

            <Badge style={{ position: 'absolute', top: '10px', right: '6px' }}>{t.ttl} ms</Badge>
          </CardHeader>

          <CardBody className='p-2'>
            {/* <pre>{JSON.stringify(t)}</pre> */}
            {(t.organization && t.organization.id) &&
             <div style={{ fontSize: '85%' }}><Badge className='mr-1'>{t.organization.id}</Badge> {t.organization.handle || 'N/A'}</div>
            }

            <div style={{ fontSize: '85%' }}>
              {(t.city && t.city.name) &&
                <span className='mr-1'>{t.city.name}</span>
              }
              {(t.subdivisions && t.subdivisions.length) &&
                <span className='mr-1'>{t.subdivisions[0].name}</span>
              }

              {t.postalCode &&
                <span className='mr-1'>{t.postalCode}</span>
              }
            </div>
          </CardBody>
        </Card>
      ))
    }

    const ddd = Object.entries(this.state.pry).map(([key, value]) => (
      <div className='server mr-2' key={key} style={{position: 'relative'}}>
        <h4 className='mt-2'>
          {key}
          {(value && value.traceroute) && <span>({value.traceroute.length})</span> }
        </h4>

        <div className='mb-3 text-muted' style={{ fontSize: '85%' }}>{value.location}<br />{value.datacenter}</div>

        {(value && value.traceroute) && <Badge style={{position: 'absolute', top: '15px', right: '8px'}}>{value.resolveTime} ms</Badge> }
        {(value && value.traceroute && value.traceroute.length) &&
         <div>
           {bll(value.traceroute)}
         </div>
        }

        {(value && !value.traceroute) &&
          <FontAwesomeIcon className='ml-2 text-muted' icon='circle-notch' spin />
        }
      </div>
    ))

    return (
      <div>
        <Row>
          <Col lg={2}>
            <Card className='mt-3'>
                {query &&
                 <CardHeader className='p-3' style={{fontSize: '80%'}}>
                   {(query.type && query.type === 'host') &&
                    <div>
                      <h3 className='p-0 m-0' style={{fontSize: '20px'}}>{query.hostname}</h3>
                      {(query.addresses && query.addresses.length) &&
                        <div className='text-muted'>
                          {query.addresses.join(' ')}
                        </div>
                      }
                    </div>
                   }
                   {(!query.type) &&
                    <div>
                      <h3 className='p-0 m-0' style={{ fontSize: '20px'}}>{query.address}</h3>
                      {(query.reverseDns && query.reverseDns.length) &&
                       <div className='text-muted'>{query.reverseDns[0]}</div>
                      }
                    </div>
                   }
                 </CardHeader>
                }
                {query &&
                 <CardBody className='p-3'>
                   {(query.type && query.type === 'host') &&
                    <div>
                      {(query.ns && query.ns.length) &&
                       <div>
                         <h4 className='m-0'>NS</h4>
                        <div>{query.ns.join(', ')}</div>
                       </div>
                      }

                      {(query.txt && query.txt.length) &&
                       <div>
                         <h4 className='m-0 mt-4'>txt</h4>
                         <div>{query.txt.join(', ')}</div>
                       </div>
                      }
                    </div>
                   }

                   <div>
                     <h4 className='m-0 mt-4'>json</h4>
                     <pre><code>{JSON.stringify(query, null, '  ')}</code></pre>
                   </div>
                 </CardBody>
                }
            </Card>

          </Col>
          <Col lg={10}>
            <h3 class='text-center pt-2 pb-2 m-0' style={{textTransform: 'uppercase'}}>Traceroutes</h3>
            <div className='servers'>
              {ddd}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

}
